import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BioModel} from '../../model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})


export class ExperienceComponent implements OnInit, OnChanges {
  @Output() isStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formExperience: FormGroup;
  experiences: any;
  private experience: FormArray;

  private inputData: BioModel;

  @Input() data: Subject<any>;

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  private populateData() {
    if (this.inputData !== undefined) {
      this.experience.clear();
      for (const index in this.inputData.business) {
        this.experience.push(this.exprienceFormBuilder.group({
          id: new FormControl(this.inputData.experience[index].id),
          employer: new FormControl(this.inputData.experience[index].employer, Validators.required),
          from: new FormControl(this.inputData.experience[index].from, Validators.required),
          to: new FormControl(this.inputData.experience[index].to, Validators.required),
          role: new FormControl(this.inputData.experience[index].role, Validators.required)
        }));
      }
    }
  }

  constructor(private exprienceFormBuilder: FormBuilder) {
    this.formExperience = new FormGroup({
      dataForms: this.exprienceFormBuilder.array([])
    });
    this.experiences = this.formExperience.controls.dataForms.value;
    this.experience = this.formExperience.get('dataForms') as FormArray;
  }

  addExpeience() {
    this.experience.push(this.exprienceFormBuilder.group({
      id: new FormControl(''),
      employer: new FormControl('', Validators.required),
      from: new FormControl('', Validators.required),
      to: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required)
    }));
  }

  deleteExperience(index: any) {
    this.experience.removeAt(index);
  }

  submitData() {
    this.formEmitter.emit(this.formExperience);
  }

  trackByFn(index, item: any) {
    return item.id;
  }
}
