import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar, MatStepper} from '@angular/material';
import {AuthResponse, BioModel} from '../model';
import {RegistrationService} from '../registration.service';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {

  @ViewChild('stepper', {static: false}) private stepper: MatStepper;
  isBioData: boolean;
  subject = new Subject<BioModel>();
  bioModel: BioModel;
  bioForm: FormGroup;
  academicForm: FormGroup;
  experienceForm: FormGroup;
  trainingForm: FormGroup;
  businessForm: FormGroup;
  projectForm: FormGroup;
  date1 = new Date('2019-08-01T00:00:00');
  academic: boolean;

  formMobile: FormGroup;
  formPin: FormGroup;
  private pin: string;
  showEnterPin: boolean;
  processing: boolean;

  constructor(
    private snackBar: MatSnackBar,
    public registrationService: RegistrationService
  ) {
    this.showEnterPin = false;
    this.formMobile = new FormGroup({
      phoneNumber: new FormControl('', Validators.required)
    });

    this.formPin = new FormGroup({
      pin: new FormControl('', Validators.required)
    });
    this.bioModel = new BioModel();
  }

  ngOnInit() {
  }

  updateModel(formData: Partial<BioModel>) {
    Object.assign(this.bioModel, formData);
  }

  getBioForm(form: FormGroup) {
    this.academic = true;
    this.bioForm = form;
    if (this.bioForm.valid) {
      this.updateModel(this.bioForm.value);
    }
    this.stepper.next();
  }

  getAcademicForm(form: FormGroup) {
    this.academicForm = form;
    if (this.academicForm.valid) {
      this.updateModel(this.academicForm.value);
    }
    this.stepper.next();
  }

  previousStep() {
    this.stepper.previous();
  }

  getExperienceForm(form: FormGroup) {
    this.experienceForm = form;
    this.updateModel({experience: this.experienceForm.value.dataForms});
    this.stepper.next();
  }

  getTraining(form: FormGroup) {
    this.trainingForm = form;
    this.updateModel({trainings: this.trainingForm.value.dataForms});
    this.stepper.next();
  }

  onStepChange($event) {

  }

  getBusinessExperience(form: FormGroup) {
    this.businessForm = form;
    this.updateModel({business: this.businessForm.value.dataForms});
    this.stepper.next();
  }

  getProjectIdeas(form: FormGroup) {
    this.projectForm = form;
    this.updateModel(this.projectForm.value);
    this.stepper.next();
  }

  getFisheries(form: FormGroup) {
    this.updateModel({interestAreas: form.value});
    this.isProcessing(true, '');
    if (this.bioModel.code !== null) {
      this.bioModel.code = this.pin;
      this.registrationService.updateParticipant(this.bioModel).subscribe((data: Partial<BioModel>) => {
        this.updateModel(data);
        this.isProcessing(false, 'Your profile has been submitted successfully.');
        this.stepper.next();
      }, (err) => {
        this.isProcessing(false, err.error.message);
      });
    } else {
      this.registrationService.setBioData(this.bioModel).subscribe((data: Partial<BioModel>) => {
        this.updateModel(data);
        this.isProcessing(false, 'Your profile been submitted successfully.');
        this.stepper.next();
      }, (err) => {
        this.isProcessing(false, err.error.message);
      });
    }
  }

  getAttachment($event) {
    this.stepper.next();
  }

  authenticateParticipant(formMobile: FormGroup) {
    this.isProcessing(true, '');
    this.registrationService.authenticateParticipant(formMobile.controls.phoneNumber.value).subscribe((resp: AuthResponse) => {
      this.showEnterPin = true;
      this.isProcessing(false, 'Enter the OTP code sent to your phone above.');
      this.formMobile.reset();
    }, (err) => {
      this.isProcessing(false, err.error.message);
    });
  }

  fetchProfile(formPin: FormGroup) {
    this.isProcessing(true, '');
    this.registrationService.setParticipantData(formPin.controls.pin.value).subscribe((data: Partial<BioModel>) => {
      this.updateModel(data);
      this.showEnterPin = false;
      this.isProcessing(false, 'Proceed to update your profile.');
      this.formPin.reset();
      this.subject.next(this.bioModel);
    }, (err) => {
      this.isProcessing(false, err.error.message);
    });
  }

  private isProcessing(state: boolean, message: string) {
    this.processing = state;
    if (message !== '') {
      this.snackBar.open(message, 'Dismiss', {
        duration: 2500
      });
    }
  }

}
