import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {subCountyList, countyList, genderList, BioModel} from '../../model';
import {Subject} from "rxjs";

@Component({
  selector: 'app-biodata',
  templateUrl: './biodata.component.html',
  styleUrls: ['./biodata.component.scss']
})

export class BiodataComponent implements OnInit, OnChanges {
  private inputData: BioModel;

  constructor() {
    this.formBio = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      idNumber: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
      mobileNo: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      ward: new FormControl('', Validators.required),
      subCounty: new FormControl('', Validators.required),
      county: new FormControl('', Validators.required),
      groupMemberShip: new FormControl('')
    });
  }

  subCounty;
  wardName: [];

  gender;
  subcountyWardList = [];

  genderList = genderList;
  countyList = countyList;
  subcountyList: any = subCountyList;

  @Input() data: Subject<any>;

  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter();
  formBio: FormGroup;

  subcountyChangeAction(subcounty) {
    this.wardName = [];
    const dropDownData = this.subcountyList.find((data: any) => data.subcountyName === subcounty);
    if (dropDownData) {
      this.subcountyWardList = dropDownData.subcountyWardList;
      if (this.subcountyWardList) {
        this.wardName = this.subcountyWardList[0];
      }
    } else {
      this.subcountyWardList = [];
    }

  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  submitData() {
    this.formEmitter.emit(this.formBio);
  }

  private populateData() {
    this.subcountyChangeAction(this.inputData.subCounty);
    this.formBio.get('id').setValue(this.inputData.id);
    this.formBio.get('name').setValue(this.inputData.name);
    this.formBio.get('gender').setValue(this.inputData.gender);
    this.formBio.get('idNumber').setValue(this.inputData.idNumber);
    this.formBio.get('dateOfBirth').setValue(this.inputData.dateOfBirth);
    this.formBio.get('mobileNo').setValue(this.inputData.mobileNo);
    this.formBio.get('email').setValue(this.inputData.email);
    this.formBio.get('address').setValue(this.inputData.address);
    this.formBio.get('ward').setValue(this.inputData.ward);
    this.formBio.get('subCounty').setValue(this.inputData.subCounty);
    this.formBio.get('county').setValue(this.inputData.county);
    this.formBio.get('groupMemberShip').setValue(this.inputData.groupMemberShip);
  }
}
