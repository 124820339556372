import {Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BioModel} from '../../model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-businessexperience',
  templateUrl: './businessexperience.component.html',
  styleUrls: ['./businessexperience.component.scss']
})
export class BusinessexperienceComponent implements OnInit, OnChanges {
  BusinessExperience = {revenue: [], capital: [], };
  typesOfRevenue: string[] = ['Kshs.0', 'Kshs. 1-10,000', 'Kshs. 10,000-100,000', 'Kshs. 100,000-1,000,000', 'Kshs. Over 1,000,000'];
  typesOfCapital: string[] = ['Self', 'Friends & Family', 'Investments', 'Loans', 'Grants'];
  businessExpriencesForm: FormGroup;
  step = 0;

  @Output() isStep: EventEmitter<boolean> = new EventEmitter();
  @Output() formEmitter: EventEmitter<any> = new EventEmitter();
  private inputData: BioModel;
  private experience: FormArray;

  constructor(private businessExperienceBuilder: FormBuilder) {
    this.businessExpriencesForm = new FormGroup({
      dataForms: this.businessExperienceBuilder.array([])
    });
    this.experience = this.businessExpriencesForm.get('dataForms') as FormArray;
  }

  @Input() data: Subject<any>;

  addBussines() {
    this.experience.push(this.businessExperienceBuilder.group({
      areaOfBusiness: new FormControl('', Validators.required),
      annualBusiness: new FormControl('', Validators.required),
      capital: new FormControl('', Validators.required)
    }));
  }

  deleteBusiness(index: any) {
    this.experience.removeAt(index);
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  private populateData() {
    this.experience.clear();
    if (this.inputData !== undefined) {
      for (const index in this.inputData.business) {
        this.experience.push(this.businessExperienceBuilder.group({
          id: new FormControl(this.inputData.business[index].id),
          areaOfBusiness: new FormControl(this.inputData.business[index].areaOfBusiness, Validators.required),
          annualBusiness: new FormControl(this.inputData.business[index].annualBusiness, Validators.required),
          capital: new FormControl(this.inputData.business[index].capital, Validators.required)
        }));
      }
    }
  }

  trackByFn(index, item: any) {
    return item.id;
  }

  submitData() {
    this.formEmitter.emit(this.businessExpriencesForm);
  }

}
