import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BioModel} from '../../model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit, OnChanges {
  @Output() isStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formTrainings: FormGroup;

  private inputData: BioModel;

  @Input() data: Subject<any>;
  private training: FormArray;

  constructor(private trainingsFormBuilder: FormBuilder) {
    this.formTrainings = new FormGroup({
      dataForms: this.trainingsFormBuilder.array([])
    });
    this.training = this.formTrainings.get('dataForms') as FormArray;
  }

  addTraining() {
    this.training.push(this.trainingsFormBuilder.group({
      id: new FormControl(''),
      training: new FormControl('', Validators.required)
    }));
  }

  deleteTraining(index: any) {
    this.training.removeAt(index);
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  private populateData() {
    if (this.inputData !== undefined) {
      this.training.clear();
      for (const index in this.inputData.trainings) {
        this.training.push(this.trainingsFormBuilder.group({
          id: new FormControl(this.inputData.trainings[index].id),
          training: new FormControl(this.inputData.trainings[index].training, Validators.required)
        }));
      }
    }
  }

  submitData() {
    this.formEmitter.emit(this.formTrainings);
  }

  trackByFn(index, item: any) {
    return item.id;
  }

}
