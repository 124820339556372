interface Experience {
  id?: string;
  employer: string;
  from: Date;
  to: Date;
  role: string;
}

interface Training {
  id?: string;
  training: string;
}

export class BaseModel {
  id?: string;
  name: string;
  mobileNo?: string;
  email: string;
  address: string;
  ward: string;
  subCounty: string;
  county: string;
  experience: Experience[];
  trainings: Training[];
  interestAreas: any;
  business?: any[];
  code?: string;
  projectIdea: string;
  projectValueProposition: string;
  projectChallenge: string;
  projectImpact: string;
}

export class BioModel extends BaseModel {
  gender: boolean;
  idNumber: string;
  dateOfBirth: Date;
  groupMemberShip: boolean;
  primaryEducation?: boolean;
  yearOfPrimaryGraduation?: boolean;
  secondaryEducation?: boolean;
  yearOfSecondary?: boolean;
  diplomaCertificate?: string;
  diplomaCourse?: string;
  yearOfDiplomaCompletion?: string;
  degreeCertificate?: string;
  degreeCourse?: string;
  yearOfCompletion?: string;
}

export class GroupModel extends BaseModel {
  dateFormed?: string;
  registrationStatus?: boolean;
  registeringAuthority?: string;
  registrationNumber?: string;
  contactPerson?: string;
  organizationActivities: any[];
  organizationPartners: any[];
  organizationIncomeSponsorship: boolean;
  organizationLastIncome?: string;
  organizationMembers: any[];
  memberNumber: string;
}

export class AuthResponse {
  message: string;
  pin: string;
}

/**
 * @todo convert this to typings
 * @param model bio data APIs
 */
export const getBioDataToApis = (model: BioModel) => {
  const apiData = {
    gender: model.gender,
    id_number: model.idNumber,
    address: model.address,
    mobile_number: model.mobileNo,
    ward: model.ward,
    subcounty: model.subCounty,
    county: model.county,
    email: model.email,
    date_of_birth: model.dateOfBirth,
    name: model.name,
    group_member: model.groupMemberShip,
    interest_areas: model.interestAreas,
    experience: model.experience,
    trainings: model.trainings,
    diploma_year_of_graduation: model.yearOfDiplomaCompletion,
    diploma_education: model.diplomaCertificate,
    diploma_training_area: model.diplomaCourse,
    undergraduate_education: model.degreeCertificate,
    undergraduate_training_area: model.degreeCourse,
    undergraduate_year_of_graduation: model.yearOfCompletion,
    primary_education: model.primaryEducation,
    primary_year_of_graduation: model.yearOfPrimaryGraduation,
    secondary_education: model.secondaryEducation,
    secondary_year_of_graduation: model.yearOfSecondary,
    business: model.business,
    project_idea: model.projectIdea,
    project_value_proposition: model.projectValueProposition,
    project_challenge: model.projectChallenge,
    project_impact: model.projectImpact,
    code: model.code
  };
  return apiData;
};

export const getGroupDataToApis = (model: GroupModel) => {
  const apiData = {
    address: model.address,
    date_formed: model.dateFormed,
    registered: model.registrationStatus,
    registering_authority: model.registeringAuthority,
    registration: model.registrationNumber,
    contact_person: model.contactPerson,
    mobile_number: model.mobileNo,
    ward: model.ward,
    sub_county: model.subCounty,
    county: model.county,
    email: model.email,
    name: model.name,
    interest_areas: model.interestAreas,
    experience: model.experience,
    organization_members: model.organizationMembers,
    organization_activities: model.organizationActivities,
    member_count: model.memberNumber,
    organization_partners: model.organizationPartners,
    organization_income_sponsorship: model.organizationIncomeSponsorship,
    organization_last_income: model.organizationLastIncome,
    trainings: model.trainings,
    business: model.business,
    project_idea: model.projectIdea,
    project_value_proposition: model.projectValueProposition,
    project_challenge: model.projectChallenge,
    project_impact: model.projectImpact,
    code: model.code
  };
  return apiData;
};

export const genderList = ['Male', 'Female'];

export const countyList = ['Kisumu'];

export const subCountyList: any = [
  {
    subcountyName: 'Kisumu East',
    subcountyWardList: [
      'KAJULU', 'KOLWA CENTRAL', 'KOLWA EAST', 'MANYATTA B', 'NYALENDA A',
    ]
  },
  {
    subcountyName: 'Kisumu Central',
    subcountyWardList: [
      'KALOLENI', 'KONDELE', '  MARKET MILIMANI', 'MIGOSI', 'NYALENDA ‘B’', 'RAILWAYS', 'SHAURIMOYO',
    ]
  },
  {
    subcountyName: 'Kisumu West',
    subcountyWardList: ['CENTRAL KISUMU', 'KISUMU NORTH', 'NORTH WEST KISUMU', 'SOUTH WEST KISUMU', 'WEST KISUMU',
    ]
  },
  {
    subcountyName: 'Muhoroni',
    subcountyWardList: ['MIWANI', 'OMBEYI', 'MASOGO/NYANG’OMA', 'CHEMELIL', 'MUHORONI/KORU',
    ]
  },
  {
    subcountyName: 'Nyakach',
    subcountyWardList: ['SOUTH EAST NYAKACH', 'WEST NYAKACH', 'NORTH NYAKACH', 'CENTRAL NYAKACH', 'SOUTH WEST NYAKACH',
    ]
  },
  {
    subcountyName: 'Nyando',
    subcountyWardList: ['EAST KANO/WAIDHI', 'AWASI/ONJIKO', 'AHERO', 'KABONYO/KANYAG WAL', 'KOBURA',
    ]
  },
  {
    subcountyName: 'Seme',
    subcountyWardList: ['WEST SEME', 'CENTRAL SEME', 'EAST SEME', 'NORTH SEME',
    ]
  },

];
