import {Injectable} from '@angular/core';
import {AuthResponse, BioModel, getBioDataToApis, getGroupDataToApis, GroupModel} from './model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';

function setTransformBioData(res: any) {
  const bioData: BioModel = new BioModel();

  bioData.id = res.data.id;
  bioData.name = res.data.name;
  bioData.dateOfBirth = res.data.date_of_birth;
  bioData.email = res.data.email;
  bioData.mobileNo = res.data.mobile_number;
  bioData.groupMemberShip = res.data.group_membership;
  bioData.subCounty = res.data.subcounty;
  bioData.county = res.data.county;
  bioData.ward = res.data.ward;
  bioData.idNumber = res.data.id_number;
  bioData.address = res.data.address;
  bioData.gender = res.data.gender;
  bioData.primaryEducation = res.data.primary_education;
  bioData.yearOfPrimaryGraduation = res.data.primary_year_of_graduation;
  bioData.secondaryEducation = res.data.seconday_education;
  bioData.yearOfSecondary = res.data.secondary_year_of_graduation;
  bioData.diplomaCertificate = res.data.diploma_education;
  bioData.diplomaCourse = res.data.diploma_training_area;
  bioData.yearOfDiplomaCompletion = res.data.diploma_year_of_graduation;
  bioData.degreeCertificate = res.data.undergraduate_education;
  bioData.degreeCourse = res.data.undergraduate_training_area;
  bioData.yearOfCompletion = res.data.undergraduate_year_of_graduation;
  bioData.experience = res.data.experience;
  bioData.trainings = res.data.trainings;
  bioData.interestAreas = res.data.interest_areas;
  bioData.business = res.data.business;
  bioData.projectIdea = res.data.project_idea;
  bioData.projectValueProposition = res.data.project_value_proposition;
  bioData.projectChallenge = res.data.project_challenge;
  bioData.projectImpact = res.data.project_impact;
  bioData.code = res.data.code;

  return bioData;
}

function setTransformGroupData(res: any) {
  const groupData: GroupModel = new GroupModel();

  groupData.id = res.data.id;
  groupData.address = res.data.address;
  groupData.dateFormed = res.data.date_formed;
  groupData.registrationStatus = res.data.registered;
  groupData.registeringAuthority = res.data.registering_authority;
  groupData.registrationNumber = res.data.registration;
  groupData.contactPerson = res.data.contact_person;
  groupData.mobileNo = res.data.mobile_number;
  groupData.ward = res.data.ward;
  groupData.subCounty = res.data.sub_county;
  groupData.county = res.data.county;
  groupData.email = res.data.email;
  groupData.name = res.data.name;
  groupData.interestAreas = res.data.interest_areas;
  groupData.experience = res.data.experience;
  groupData.organizationMembers = res.data.organization_members;
  groupData.organizationActivities = res.data.organization_activities;
  groupData.memberNumber = res.data.member_count;
  groupData.organizationPartners = res.data.organization_partners;
  groupData.organizationIncomeSponsorship = res.data.organization_income_sponsorship;
  groupData.organizationLastIncome = res.data.organization_last_income;
  groupData.trainings = res.data.trainings;
  groupData.business = res.data.business;
  groupData.projectIdea = res.data.project_idea;
  groupData.projectValueProposition = res.data.project_value_proposition;
  groupData.projectChallenge = res.data.project_challenge;
  groupData.projectImpact = res.data.project_impact;

  return groupData;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  headers: any;
  options: any;
  registrantUrl: string = environment.baseUrl + 'registrant/';
  groupUrl: string = environment.baseUrl + 'group/';
  genericAuthUrl: string = environment.baseUrl + 'auth/auth/';
  registrantAuthUrl: string = environment.baseUrl + 'auth/registrant/';
  groupAuthUrl: string = environment.baseUrl + 'auth/group/';

  constructor(private httpClient: HttpClient) {
  }

  setBioData(participant: BioModel) {
    return this.httpClient.post(this.registrantUrl, getBioDataToApis(participant)).pipe(
      map((res: any) => {
        return setTransformGroupData(res);
      })
    );
  }

  updateParticipant(participant: BioModel) {
    return this.httpClient.put(this.registrantUrl + participant.id + '/', getBioDataToApis(participant)).pipe(
      map((res: any) => {
        return setTransformBioData(res);
      })
    );
  }

  setGroupData(group: GroupModel) {
    return this.httpClient.post(this.groupUrl, getGroupDataToApis(group)).pipe(
      map((res: any) => {
        return setTransformGroupData(res);
      })
    );
  }

  updateGroup(group: GroupModel) {
    return this.httpClient.put(this.groupUrl + group.id + '/', getGroupDataToApis(group)).pipe(
      map((res: any) => {
        return setTransformBioData(res);
      })
    );
  }

  authenticateParticipant(id: string) {
    return this.httpClient.post(this.registrantAuthUrl, {mobile_number: id}).pipe(
      map((res: any) => {
        const authResponse: AuthResponse = new AuthResponse();
        authResponse.pin = res.data.pin;
        return authResponse;
      })
    );
  }

  authenticateGroup(id: string) {
    return this.httpClient.post(this.groupAuthUrl, {mobile_number: id}).pipe(
      map((res: any) => {
        const authResponse: AuthResponse = new AuthResponse();
        authResponse.pin = res.data.pin;
        return authResponse;
      })
    );
  }

  setParticipantData(id: string) {
    return this.httpClient.get(this.registrantUrl + id + '/').pipe(
      map((res: any) => {
        return setTransformBioData(res);
      })
    );
  }

  setParticipatingGroupData(id: string) {
    return this.httpClient.get(this.groupUrl + id + '/').pipe(
      map((res: any) => {
        return setTransformGroupData(res);
      })
    );
  }

}
