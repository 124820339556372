import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BioModel} from '../../model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.scss']
})
export class AcademicComponent implements OnInit, OnChanges {

  @Output() isStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formAcademic: FormGroup;

  private inputData: BioModel;

  @Input() data: Subject<any>;

  constructor() {
    this.formAcademic = new FormGroup({
      primaryEducation: new FormControl('', Validators.required),
      yearOfPrimaryGraduation: new FormControl('', Validators.required),
      secondaryEducation: new FormControl('', Validators.required),
      yearOfSecondary: new FormControl('', Validators.required),
      diplomaCertificate: new FormControl('', Validators.required),
      diplomaCourse: new FormControl('', Validators.required),
      yearOfDiplomaCompletion: new FormControl('', Validators.required),
      degreeCertificate: new FormControl('', Validators.required),
      degreeCourse: new FormControl('', Validators.required),
      yearOfCompletion: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  private populateData() {
    this.formAcademic.get('primaryEducation').setValue(this.inputData.primaryEducation ? '1' : '0');
    this.formAcademic.get('yearOfPrimaryGraduation').setValue(this.inputData.yearOfPrimaryGraduation);
    this.formAcademic.get('secondaryEducation').setValue(this.inputData.secondaryEducation ? '1' : '0');
    this.formAcademic.get('yearOfSecondary').setValue(this.inputData.yearOfSecondary);
    this.formAcademic.get('diplomaCertificate').setValue(this.inputData.diplomaCertificate ? '1' : '0');
    this.formAcademic.get('diplomaCourse').setValue(this.inputData.diplomaCourse);
    this.formAcademic.get('yearOfDiplomaCompletion').setValue(this.inputData.yearOfDiplomaCompletion);
    this.formAcademic.get('degreeCertificate').setValue(this.inputData.degreeCertificate ? '1' : '0');
    this.formAcademic.get('degreeCourse').setValue(this.inputData.degreeCourse);
    this.formAcademic.get('yearOfCompletion').setValue(this.inputData.yearOfCompletion);
  }

  submitData() {
    this.formEmitter.emit(this.formAcademic);
  }

  previousStep() {
    this.isStep.emit(false);
  }


}
