import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseModel, BioModel} from '../../model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UploadService} from '../../upload.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnChanges {

  @Input() model: BaseModel;
  @Output() isStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() attachmentForm: EventEmitter<any> = new EventEmitter<any>();
  files: File[];
  showSubmit: boolean;
  form: FormGroup;
  error: string;
  uploadResponse = {status: '', message: '', filePath: ''};

  constructor(private httpClient: HttpClient, private uploadService: UploadService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      document: ['']
    });
    this.showSubmit = false;
  }

  ngOnInit() {

  }

  ngOnChanges(simple: SimpleChanges) {
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('document').setValue(file);
      this.showSubmit = true;
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('document', this.form.get('document').value);

    if (this.model instanceof BioModel) {
      formData.append('registrant', this.model.id);
    } else {
      formData.append('group', this.model.id);
    }

    this.uploadService.upload(formData).subscribe(
      (res) => {
        if (res.status) {
          this.clearForm();
        }
        this.uploadResponse = res;
      },
      (err) => this.error = err
    );
  }

  clearForm() {
    this.form.reset();
  }

  submitData() {
    this.attachmentForm.emit(1);
  }

}
