import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {BioModel} from '../../model';


@Component({
  selector: 'app-valuechain',
  templateUrl: './valuechain.component.html',
  styleUrls: ['./valuechain.component.scss']
})
export class ValuechainComponent implements OnInit {
  FisheryValueAdditions = {
    inputs: [],
    transports: [],
    valueAdditions: [],
    wholesale: [],
    marketing: [],
    consumers: [],
    supportingServices: []
  };
  typesOfInputs: string[] = ['Fingerlings', 'Feeds', 'Nets', 'Fish farm materials', 'Agrovet shops',
    'Fish farming', 'Fishing/harvesting'];
  typesOfTransport: string[] = ['Lake', 'Road', 'Air'];
  typesOfValueAdditions: string[] = ['Collecting & Grading', 'Processing'];
  typesOfWholesaling: string[] = ['Animal feed', 'Fresh-fish', 'Processed', 'Retailing', 'Aqua shops'];
  typesOfMarketing: string[] = ['Export markets', 'Supermarkets', 'Open-air markets'];
  typesOfConsumers: string[] = ['Rural consumers', 'Urban consumers'];
  typesOfSupportingServices: string[] = ['Financial eg. Banks, MFIs', 'Government', 'Training', 'Research',
    'Legislation', 'Non-Governmental organisations', 'Technology/innovations'];
  dataForm: FormGroup;
  step = 0;
  @Output() isStep: EventEmitter<boolean> = new EventEmitter();
  @Output() formEmitter: EventEmitter<any> = new EventEmitter();

  @Input() data: Subject<any>;

  private inputData: BioModel;

  constructor() {
    this.dataForm = new FormGroup({
      consumers: new FormControl(),
      marketing: new FormControl(),
      wholesale: new FormControl(),
      inputs: new FormControl(),
      valueAdditions: new FormControl(),
      supportingServices: new FormControl(),
      transports: new FormControl(),
    });
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  private populateData() {
    this.dataForm = new FormGroup({
      consumers: new FormControl(this.inputData.interestAreas.consumers),
      marketing: new FormControl(this.inputData.interestAreas.marketing),
      wholesale: new FormControl(this.inputData.interestAreas.wholesale),
      inputs: new FormControl(this.inputData.interestAreas.inputs),
      valueAdditions: new FormControl(this.inputData.interestAreas.valueAdditions),
      supportingServices: new FormControl(this.inputData.interestAreas.supportingServices),
      transports: new FormControl(this.inputData.interestAreas.transports)
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    if (this.step === 0 || this.step === 6) {
    }
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  submitData() {
    this.formEmitter.emit(this.dataForm);
  }

}
