import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from '../shared/material.module';
import {ParticipantComponent} from './participant/participant.component';
import {BiodataComponent} from './participant/biodata/biodata.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AcademicComponent} from './participant/academic/academic.component';
import {LandingpageComponent} from './landingpage/landingpage.component';
import {GroupregistrationComponent} from './groupregistration/groupregistration.component';
import {ExperienceComponent} from './participant/experience/experience.component';
import {TrainingsComponent} from './participant/trainings/trainings.component';
import {ValuechainComponent} from './participant/valuechain/valuechain.component';
import {HttpClientModule} from '@angular/common/http';
import {AttachmentsComponent} from './participant/attachments/attachments.component';
import {CountdownTimerModule} from 'angular-countdown-timer';
import {BusinessexperienceComponent} from './participant/businessexperience/businessexperience.component';
import {ProjectideasComponent} from './participant/projectideas/projectideas.component';
import {GroupdetailsComponent} from './groupregistration/groupdetails/groupdetails.component';
import {GroupExperienceComponent} from './groupregistration/group-experience/group-experience.component';
import {InternationalPhoneNumberModule} from 'ngx-international-phone-number';


@NgModule({
  declarations: [
    AppComponent,
    ParticipantComponent,
    BiodataComponent,
    AcademicComponent,
    LandingpageComponent,
    GroupregistrationComponent,
    ExperienceComponent,
    TrainingsComponent,
    ValuechainComponent,
    AttachmentsComponent,
    BusinessexperienceComponent,
    ProjectideasComponent,
    GroupdetailsComponent,
    GroupExperienceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    CountdownTimerModule.forRoot(),
    InternationalPhoneNumberModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
