import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ParticipantComponent} from './participant/participant.component';
import {LandingpageComponent} from './landingpage/landingpage.component';
import {GroupregistrationComponent} from './groupregistration/groupregistration.component';

const routes: Routes = [
  {path: 'participant', component: ParticipantComponent},
  {path: 'group', component: GroupregistrationComponent},
  {path: '**', component: LandingpageComponent},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
