import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {GroupModel} from '../../model';

@Component({
  selector: 'app-group-experience',
  templateUrl: './group-experience.component.html',
  styleUrls: ['./group-experience.component.scss']
})
export class GroupExperienceComponent implements OnInit {

  @Output() isStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formGroupExperience: FormGroup;

  @Input() data: Subject<any>;

  statusList = ['Yes', 'No'];
  hasIncomeStatus;

  incomeList = ['Kshs. 0', 'Kshs. 1-10,000', 'Kshs. 10,000-100,000', 'Kshs. 100,000-1,000,000'];
  annualIncome;

  private inputData: GroupModel;

  constructor(private groupExpFormBuilder: FormBuilder) {
    this.formGroupExperience = new FormGroup({
      memberNumber: new FormControl('', Validators.required),
      memberForms: this.groupExpFormBuilder.array([]),
      activityForms: this.groupExpFormBuilder.array([]),
      partnerForms: this.groupExpFormBuilder.array([]),
      organizationIncomeSponsorship: new FormControl('', Validators.required),
      organizationLastIncome: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  get formMembersArr() {
    return this.formGroupExperience.get('memberForms') as FormArray;
  }

  addMember() {
    const member = this.formGroupExperience.get('memberForms') as FormArray;
    member.push(this.groupExpFormBuilder.group({
      id: new FormControl(''),
      member: new FormControl('', Validators.required)
    }));
  }

  deleteMember(index: any) {
    this.formMembersArr.removeAt(index);
  }

  get formActivitiesArr() {
    return this.formGroupExperience.get('activityForms') as FormArray;
  }

  addActivity() {
    const activity = this.formGroupExperience.get('activityForms') as FormArray;
    activity.push(this.groupExpFormBuilder.group({
      id: new FormControl(''),
      activity: new FormControl('', Validators.required)
    }));
  }

  deleteActivity(index: any) {
    this.formActivitiesArr.removeAt(index);
  }

  get formPartnersArr() {
    return this.formGroupExperience.get('partnerForms') as FormArray;
  }

  addPartner() {
    const partner = this.formGroupExperience.get('partnerForms') as FormArray;
    partner.push(this.groupExpFormBuilder.group({
      id: new FormControl(''),
      partner: new FormControl('', Validators.required)
    }));
  }

  deletePartner(index: any) {
    this.formPartnersArr.removeAt(index);
  }

  submitData() {
    this.formEmitter.emit(this.formGroupExperience);
  }

  trackByFn(index, item: any) {
    return item.id;
  }

  private populateData() {
    if (this.inputData !== undefined) {
      this.formGroupExperience.get('memberNumber').setValue(this.inputData.memberNumber);
      this.formGroupExperience.get('organizationIncomeSponsorship').setValue(this.inputData.organizationIncomeSponsorship);
      this.formGroupExperience.get('organizationLastIncome').setValue(this.inputData.organizationLastIncome);
      const members = this.formGroupExperience.get('memberForms') as FormArray;
      members.clear();
      for (const index in this.inputData.organizationMembers) {
        members.push(this.groupExpFormBuilder.group({
          id: new FormControl(this.inputData.organizationMembers[index].id),
          member: new FormControl(this.inputData.organizationMembers[index].member, Validators.required),
        }));
      }

      const activities = this.formGroupExperience.get('activityForms') as FormArray;
      for (const index in this.inputData.organizationActivities) {
        activities.push(this.groupExpFormBuilder.group({
          id: new FormControl(this.inputData.organizationActivities[index].id),
          activity: new FormControl(this.inputData.organizationActivities[index].activity, Validators.required),
        }));
      }

      const partners = this.formGroupExperience.get('partnerForms') as FormArray;
      for (const index in this.inputData.organizationPartners) {
        partners.push(this.groupExpFormBuilder.group({
          id: new FormControl(this.inputData.organizationPartners[index].id),
          partner: new FormControl(this.inputData.organizationPartners[index].partner, Validators.required),
        }));
      }

    }
  }
}
