import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar, MatStepper} from '@angular/material';
import {AuthResponse, GroupModel} from '../model';
import {RegistrationService} from '../registration.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-groupregistration',
  templateUrl: './groupregistration.component.html',
  styleUrls: ['./groupregistration.component.scss']
})
export class GroupregistrationComponent implements OnInit {
  subject = new Subject<GroupModel>();
  formMobile: FormGroup;
  formPin: FormGroup;
  private pin: string;
  showEnterPin: boolean;
  processing: boolean;
  private id: string;

  constructor(
    private snackBar: MatSnackBar,
    public registrationService: RegistrationService
  ) {
    this.showEnterPin = false;
    this.formMobile = new FormGroup({
      // idNumber: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required)
    });

    this.formPin = new FormGroup({
      pin: new FormControl('', Validators.required)
    });

    this.groupModel = new GroupModel();
  }

  @ViewChild('stepper', {static: false}) private stepper: MatStepper;
  groupModel: GroupModel;
  groupForm: FormGroup;
  trainingForm: FormGroup;
  projectIdeas: FormGroup;
  date2 = new Date('2019-08-14T00:00:00');

  ngOnInit() {
  }

  onStepChange($event) {

  }

  previousStep() {
    this.stepper.previous();
  }

  updateModel(formData: Partial<GroupModel>) {
    Object.assign(this.groupModel, formData);
  }

  getAdminForm(form: FormGroup) {
    this.groupForm = form;
    if (this.groupForm.valid) {
      this.groupModel = this.groupForm.value;
      this.updateModel(this.groupForm.value);
    }
    this.stepper.next();
  }

  getGroupExperienceForm(form: FormGroup) {
    this.updateModel({memberNumber: form.controls.memberNumber.value});
    this.updateModel({organizationIncomeSponsorship: form.controls.organizationIncomeSponsorship.value});
    this.updateModel({organizationLastIncome: form.controls.organizationLastIncome.value});
    this.updateModel({organizationMembers: form.value.memberForms});
    this.updateModel({organizationActivities: form.value.activityForms});
    this.updateModel({organizationPartners: form.value.partnerForms});
    this.stepper.next();
  }

  getTraining(form: FormGroup) {
    this.trainingForm = form;
    this.updateModel({trainings: this.trainingForm.value.dataForms});
    this.stepper.next();
  }

  getProjectIdeas(form: FormGroup) {
    this.projectIdeas = form;
    this.updateModel(this.projectIdeas.value);
    this.stepper.next();
  }

  getFisheries(form: FormGroup) {
    this.updateModel({interestAreas: form.value});
    this.updateModel({id: this.id});
    this.isProcessing(true, '');
    if (this.groupModel.code !== null) {
      this.groupModel.code = this.pin;
      this.registrationService.updateGroup(this.groupModel).subscribe((data: Partial<GroupModel>) => {
        this.groupModel = new GroupModel();
        this.isProcessing(false, 'Your group profile has been submitted successfully.');
        this.stepper.next();
      }, (err) => {
        this.isProcessing(false, err.error.message);
      });
    } else {
      this.registrationService.setGroupData(this.groupModel).subscribe((data: Partial<GroupModel>) => {
        this.groupModel = new GroupModel();
        this.isProcessing(false, 'Your group profile has been submitted successfully.');
        this.stepper.next();
      }, (err) => {
        this.isProcessing(false, err.error.message);
      });
    }
  }

  getAttachment($event) {
    this.stepper.next();
  }


  authenticateGroup(formMobile: FormGroup) {
    this.isProcessing(true, '');
    this.registrationService.authenticateGroup(formMobile.controls.phoneNumber.value).subscribe((resp: AuthResponse) => {
      this.showEnterPin = true;
      this.isProcessing(false, 'Enter the OTP code sent to your phone above.');
      this.formMobile.reset();
    }, (err) => {
      this.isProcessing(false, err.error.message);
    });
  }

  fetchGroup(formPin: FormGroup) {
    this.isProcessing(true, '');
    this.registrationService.setParticipatingGroupData(formPin.controls.pin.value).subscribe((data: Partial<GroupModel>) => {
      this.id = data.id;
      this.updateModel(data);
      this.showEnterPin = false;
      this.isProcessing(false, 'Proceed to update your profile.');
      this.formPin.reset();
      this.subject.next(this.groupModel);
    }, (err) => {
      this.isProcessing(false, err.error.message);
    });
  }

  private isProcessing(state: boolean, message: string) {
    this.processing = state;
    if (message !== '') {
      this.snackBar.open(message, 'Dismiss', {
        duration: 2500
      });
    }
  }

}
