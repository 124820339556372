import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseModel} from '../../model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-projectideas',
  templateUrl: './projectideas.component.html',
  styleUrls: ['./projectideas.component.scss']
})
export class ProjectideasComponent implements OnInit, OnChanges {
  @Output() isStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formProject: FormGroup;
  private inputData: BaseModel;

  @Input() data: Subject<any>;

  constructor() {
    this.formProject = new FormGroup({
      projectChallenge: new FormControl('', Validators.required),
      projectIdea: new FormControl('', Validators.required),
      projectValueProposition: new FormControl('', Validators.required),
      projectImpact: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    if (this.data != null) {
      this.data.subscribe((event) => {
        this.inputData = event;
        this.populateData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  private populateData() {
    this.formProject.get('projectChallenge').setValue(this.inputData.projectChallenge);
    this.formProject.get('projectIdea').setValue(this.inputData.projectIdea);
    this.formProject.get('projectValueProposition').setValue(this.inputData.projectValueProposition);
    this.formProject.get('projectImpact').setValue(this.inputData.projectImpact);
  }

  submitData() {
    this.formEmitter.emit(this.formProject);
  }

}
